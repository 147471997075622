import Logo from "./assets/logo.png";
import Background from "./assets/Hero_Background.png";
import Hero_Foreground from "./assets/Hero_Foreground.png";
import Product_1 from "./assets/Product 01.png";
import Product_2 from "./assets/Product 02.png";
import Product_3 from "./assets/Product 03.png";
import Product_4 from "./assets/Product 04.png";
import Product_5 from "./assets/Product 05.png";
import Product_6 from "./assets/Product 06.png";
import Product_7 from "./assets/Product 07.png";
import Product_8 from "./assets/Product 08.png";
import Product_9 from "./assets/Product 09.png";
import Product_10 from "./assets/Product 10.png";

import About_1_Background from "./assets/About_1_Background.png";
import About_1_Foreground from "./assets/About_1_Foreground.png";
import Description_1_Background from "./assets/Description_1_Background.png";
import Description_1_Foreground from "./assets/Description_1_Foreground.png";
import Sustainability_1_Background from "./assets/Sustainability_1_Background.png";
import Sustainability_1_Foreground from "./assets/Sustainability_1_Foreground.png";
import Artisan_Foreground from "./assets/Artisans_foreground.png";
import Artisan_Background from "./assets/Artisans_background.png";

// import Product_6 from "./assets/Product_6.png";
// import Product_7 from "./assets/Product_7.png";
// import Product_8 from "./assets/Product_8.png";
// import Product_9 from "./assets/Product_9.png";
// import Product_10 from "./assets/Product_10.png";

import Certification_1 from "./assets/Certifications_1.png";
import Certification_2 from "./assets/Certifications_2.png";
import Certification_3 from "./assets/Certifications_3.png";
import Certification_4 from "./assets/Certifications_4.png";
import Certification_5 from "./assets/Certifications_5.png";
import Certification_6 from "./assets/Certifications_6.png";

import About_1 from "./assets/About_1.png";
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";

const data = {
  companyName: "Ramesh Carpets",
  mainText: "Bringing new life to the old craft of carpet weaving",
  subText:
    "Manufacturing quality broadloom carpet rolls and hand-knotted rugs. With multi-shaft pile weaves and jacquard aided design for handloom and custom rug.",
  productSectionTitle: "Our Products",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Compliances and Certifications",
  buttonContent: "Send an enquiry",
  logo: Logo,
  background: Background,
  foreground: Hero_Foreground,
  about: {
    heading: "About us",
    mainText:
      "Ramesh Carpet Company was established in 1963. We have built a reputation as the carpet manufacturer ourselves via the high-quality handmade Indian carpets and export service. Our reputation for fulfilling customer expectations has enabled us to expand quickly. Our weaving, design, and development teams are well-known for their expertise. Each custom carpet is handcrafted using cutting-edge technology.",
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: "Vision",
    mainText: `To be the leading manufacturer of handmade carpets, we make floor coverings for people that know a good carpet doesn’t just tie the room together—it sets the home apart.`,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
  },

  artisans: {
    heading: "Artisans",
    mainText:
      "Each rug is a work of art by our artisans. At Ramesh Carpet Company's Bhadohi facility, hand-drawn patterns are woven into long-lasting handcrafted carpets. Instead of machines, there are looms that make their work more remarkable than a machine. Our artisans work hard to create beautiful rugs and carpets. And to achieve total client satisfaction, we closely monitor every production step.",
    foregroundImage: Artisan_Foreground,
    backgroundImage: Artisan_Background,
  },

  sustainability: {
    heading: "Sustainability",
    mainText:
      "We believe that focusing on sustainability is crucial to creating a better future for our customers and communities and have set new environmental targets for our energy, trash, and water consumption. We adhere to the Labor Act and ensure that no child labour is used at any production stage. These goals urge us to plan forward because we know that our actions now will affect the world tomorrow.",
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],
  prodArray: [
    // {
    //   title: "Handloom Jacquard Dhurrie Pile",
    //   image: Product_1,
    //   link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/jb6s0xb",
    // },
    {
      title: "Twill Pile",
      image: Product_2,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/cbrgq2j",
    },
    {
      title: "Urban",
      image: Product_3,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/t287icc",
    },
    // {
    //   title: "Zanafi",
    //   image: Product_4,
    //   link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/kf1p9cw",
    // },
    {
      title: "Fine Handloom",
      image: Product_5,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/hhb0d05",
    },
    // {
    //   title: "Flatweave",
    //   image: Product_6,
    //   link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/kf1p9cw ",
    // },
    {
      title: "Chevron",
      image: Product_7,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/g83fw0x",
    },
    {
      title: "Flat-weave high low",
      image: Product_8,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/014iayy",
    },
    {
      title: "Persian 10/10",
      image: Product_9,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/4jh4k91",
    },
    {
      title: "Handloom Dhurry Pile",
      image: Product_10,
      link: "https://catalogue.sourcewiz.co/Ramesh-Carpet/k0ds2v9",
    },
  ],
  imgArray: [
    {
      image: Certification_1,
    },
    {
      image: Certification_2,
    },
    {
      image: Certification_3,
    },
    // {
    //   image: Certification_4,
    // },
    // {
    //   image: Certification_5,
    // },
    // {
    //   image: Certification_6,
    // },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },
};

export default data;
