// import * as S from "./Sustainability.style.js";
// import Animation from "react-animate-on-scroll";

// const Sustainability = ({ data }) => {
//   return (
//     <Animation animateIn="animate__fadeInUp" animateOnce={true}>
//       <S.Container>
//         <S.Header>{data.title}</S.Header>
//         <S.Image src={data.image} />
//         <S.Text>
//           We at First Imperial Markets Inc. understand that in today's global
//           marketplace, sustainability must be ingrained in our business
//           practices. We define sustainability as a journey for our company that
//           involves ongoing learning, adaptation, and improvement of our core
//           business operations, as well as, our responses to the demands of our
//           many vendors and customers.
//           <br />
//           <br />
//           We're aiming to maximize our effect, rethink our goals, and guarantee
//           that our fundamental business plan is viewed through a sustainable
//           perspective. Recycling plastic in the environment is a pressing
//           concern of our day. There is no other answer to this problem. To
//           develop a circular economy for plastics, we are focusing on a mix of
//           initiatives.
//           <br />
//           <br />
//           We are focusing on two revolutionary areas to help address some of the
//           world's most critical challenges: recycling plastic waste to help the
//           environment and promoting sustainable solutions.
//           <br />
//           <br />
//         </S.Text>
//       </S.Container>
//     </Animation>
//   );
// };

// export default Sustainability;

import * as S from "./Sustainability.style.js";

import Animation from "react-animate-on-scroll";

const Sustainability = (data) => {
  return (
    <S.Container
      style={{
        backgroundImage:
          'url("' + data.sustainability.sustainabilityBack + '")',
      }}
    >
      <S.Box>
        <S.OffsetContent>
          <S.Heading>{data.sustainability.heading} </S.Heading>
          <S.Para>{data.sustainability.mainText}</S.Para>
        </S.OffsetContent>
        <S.Content>
          {/* <Animation animateIn="animate__fadeInRight" animateOnce={true}> */}
          <S.Image src={data.sustainability.sustainabilityFore} />
          {/* </Animation> */}
        </S.Content>
        {/* <Animation animateIn="animate__fadeInLeft" animateOnce={true}> */}

        {/* </Animation> */}
      </S.Box>

      {/* <S.Box>
        <S.Content className="hideMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_2} />
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Heading> Why First Imperial </S.Heading>
            <S.Para>
              <ol>
                <li>
                  {" "}
                  <b>On-time deliveries</b>
                  <br />
                  Because of our track record of on-time delivery, we guarantee
                  customer happiness on every purchase.{" "}
                </li>
                <br />
                <li>
                  <b>Superior Quality </b>
                  <br />
                  Best choice for top-quality products. We ensure scales of
                  operations, consistent product quality, and fast production
                  time.
                </li>
                <br />
                <li>
                  <b>Best-in-class Technology</b>
                  <br />
                  We have always believed in investing in newer and more
                  efficient technologies that focus on high quality and
                  cost-competitive manufacturing.
                </li>
                <br />
                <li>
                  <b>Research & Development</b>
                  <br />
                  Specialized technologists help to deliver customized
                  tailor-made solutions with a well-equipped R&D Centre.
                </li>
              </ol>
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content className="showMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_2} />
          </Animation>
        </S.Content>
      </S.Box>

      <S.Box>
        <S.Content>
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Heading> Vision </S.Heading>
            <S.Para>
              Our vision is to continuously strive to innovate our processes,
              products, and services to generate value for money in all our
              efforts - both internal and external. Focusing all our efforts
              toward customer satisfaction, we consider total employee
              involvement, sincerity of purpose, and commitment to our core
              objectives as the foundations of our vision.
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Image src={Desc_3} />
          </Animation>
        </S.Content>
      </S.Box>

      <S.Box>
        <S.Content className="hideMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_4} />
          </Animation>
        </S.Content>
        <S.Content>
          <Animation animateIn="animate__fadeInRight" animateOnce={true}>
            <S.Heading> Our dealings </S.Heading>
            <S.Para>
              <ul>
                <li>Film and rolls of LDPE, LLDPE, HMHDPE, HDPE</li>
                <li>
                  Purges and lumps of LDPE, HDPE, PP, HIPS, GPPS, ABS, PC and
                  Nylon6
                </li>
                <li>
                  Grindings and sweeping of LDPE HDPE, PP, HIPS, GPPS, ABS, PC
                  and Nylon6
                </li>
                <li>Articles of industrial plastics </li>
              </ul>
            </S.Para>
          </Animation>
        </S.Content>
        <S.Content className="showMedium">
          <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
            <S.Image src={Desc_4} />
          </Animation>
        </S.Content>
      </S.Box> */}

      {/* <S.Content style={{ width: "80%" }}>
        <Animation animateIn="animate__fadeInUp" animateOnce={true}>
          <S.Heading>Our dealings</S.Heading>
          <S.Para>
            <ul>
              <li>Film and rolls of LDPE, LLDPE, HMHDPE, HDPE</li>
              <li>
                Purges and lumps of LDPE, HDPE, PP, HIPS, GPPS, ABS, PC and
                Nylon6
              </li>
              <li>
                Grindings and sweeping of LDPE HDPE, PP, HIPS, GPPS, ABS, PC and
                Nylon6
              </li>
              <li>Articles of industrial plastics </li>
            </ul>
          </S.Para>
        </Animation>
      </S.Content> */}
    </S.Container>
  );
};

export default Sustainability;
