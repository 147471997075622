// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Animation from "react-animate-on-scroll";

import * as S from "./ImageGallery.style.js";
// import './index.css';

const ImageGallery = ({ id, title, imgArray, isCarousel = true }) => {
  const settings = {
    dots: false,
    className: "center",
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  console.log(imgArray);

  return (
    <S.Container id={id}>
      <Animation animateIn="animate__fadeInUp" animateOnce={true}>
        <S.Header>{"Our certifications"}</S.Header>
        {isCarousel ? (
          <Slider {...settings}>
            {imgArray.map((value, key) => (
              <S.Content>
                <S.Link href={value.link} target="_blank">
                  <S.Image src={value.image} />
                </S.Link>
              </S.Content>
            ))}
          </Slider>
        ) : (
          <S.Box>
            {imgArray.map((value, key) => (
              <S.Link href={value.link} target="_blank">
                <S.Product src={value.image} />
              </S.Link>
            ))}
          </S.Box>
        )}
      </Animation>
    </S.Container>
  );
};

export default ImageGallery;
