import * as S from './Contact.style.js';
import { send } from 'emailjs-com';
import { useState } from 'react';
import Animation from 'react-animate-on-scroll';

const Contact = () => {

    const [toSend, setToSend] = useState({
        from_name: '',
        company_name: '',
        email: '',
        mobile_number: '',
        message: '',
    });

    const onSubmit = (e) => {
        e.preventDefault();
        send(
          '',
          '',
          toSend,
          ''
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <Animation animateIn="animate__fadeInUp" animateOnce={true}>
            <S.Container id="contact">
                <S.Content>
                    
                    <S.Header>Send us an enquiry</S.Header>
                    
                    <S.Text>We will get back to address your enquiry within 24 hours.</S.Text>
                    
                    <S.FormContainer
                        autoComplete = "off"
                        method="POST"
                        onSubmit={onSubmit}
                    >
                        
                        <S.Row>
                            <S.Box>
                                <S.InputHeading>Your Name *</S.InputHeading>
                                <S.Input 
                                    type="text"
                                    name="from_name"
                                    required
                                    value={toSend.from_name}
                                    onChange={handleChange}
                                />
                            </S.Box>
                            <S.Box>
                                <S.InputHeading>Company *</S.InputHeading>
                                <S.Input 
                                    type="text"
                                    name="company_name"
                                    required
                                    value={toSend.company_name}
                                    onChange={handleChange}
                                />
                            </S.Box>
                        </S.Row>

                        <S.Row>
                            <S.Box>
                                <S.InputHeading>Email ID *</S.InputHeading>
                                <S.Input
                                    type="email"
                                    name="email"
                                    required 
                                    value={toSend.email}
                                    onChange={handleChange}
                                />
                            </S.Box>
                            <S.Box>
                                <S.InputHeading>Contact number *</S.InputHeading>
                                <S.Input 
                                    type="tel"
                                    name="mobile_number"
                                    required
                                    value={toSend.mobile_number}
                                    onChange={handleChange}
                                />
                            </S.Box>
                        </S.Row>
                        
                        <S.Row>
                            <S.Box>
                                <S.InputHeading>Message (Optional)</S.InputHeading>
                                <S.TextArea
                                    name="message"
                                    value={toSend.message}
                                    onChange={handleChange}
                                />
                            </S.Box>
                        </S.Row>

                        <S.Row>
                            <S.Button
                                as="button"
                                type="submit"
                                value="send"
                            >
                                Submit query</S.Button>
                        </S.Row>

                    </S.FormContainer>
                    

                </S.Content>   
            </S.Container>
        </Animation>         
    )
}

export default Contact;